/* icons source https://iconscout.com/icons/edit */
body{
  font-size: medium;
  font-family: Arial, Helvetica, sans-serif;
}
.tableList{
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  height: 160px;
  overflow-x: scroll;
  font-size: .95rem;
}
 th, td{
  border: 1px solid #ddd;
  padding: 10px;
}
 tr:nth-child(even){background-color: #f2f2f2;}
 tr:hover {background-color: #ddd; border-bottom: 1px solid #4b4c4c; border-top: 1px solid #4b4c4c; cursor: pointer;}
th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4b4c4c;
  color: #ffffff;
}

.inputBox{
    padding: 10px 5px;
    font-size: 14px;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    display: block;
    min-width: 0;
    width: 97%;
    border-radius: 4px;
    border:1px solid #7c7b7b;
    background-color: #ffffff;
}
.btn-container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.btn-item{
  display: inline-flex;
  padding: 0px;
  margin: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
}

.shadow{
  background-color: #73797e;
  z-index: 3;
  position: absolute;
  top: 0;
  bottom: 0;
  left:0;
  right: 0;
  opacity: .5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmation-alert{
  background: #f3f0f0;
    z-index: 999;
    opacity: 1;
    padding: 20px 50px;
    border-radius: 5px;
    position: absolute;
    top: 139px;
    left: 40%;
    button{
      margin: 3rem;
      padding: 10px;
      border: 0px;
      background: #3d6ff5;
      cursor: pointer;
    }
    h1, p{
      padding: 0;
      margin: 0;
    }
    
}


/* switch style */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 12px;
 
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: -4px;
  background-color: #939499;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #a8bbee;
}

input:checked + .slider:before {
  background-color: #3d6ff5;
}

input:focus + .slider {
  box-shadow: 0 0 1px #939499;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 25px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
}

.slider.round:before {
  border-radius: 50%;
}
/* switch style end */

.text-present{
  div{
    display: inline;
    background: green;
    color: white;
    padding: 5px 10px;
    border-radius: 15px
  }
}
.text-absent{
  div{
    display: inline;
    background: red;
    color: white;
    padding: 5px 10px;
    border-radius: 15px
  }
}

.text-holiday{
  div{
  display: inline;
    background: blue;
    color: white;
    padding: 5px 10px;
    border-radius: 15px
  }
}