$base-color: #F4F4F4;
$shine-color: rgba(229,229,229,0.8);
$animation-duration: 2.0s;
$avatar-offset: 52 + 16;

// this unfortunately uses set px widths for the background-gradient, I never got around to changing it to work with all widths :(
@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
  background-size: 600px;
}

  
body{
  margin: 0;
}

.container{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tg{
   border-collapse:collapse;
   border-spacing:0;
   border-color:#ccc;
   width: 100%;
}
.tg td{
  font-family:Arial, sans-serif;
  font-size:14px;padding:10px 5px;
  border-style:solid;
  border-width:0px;
  overflow:hidden;
  word-break:normal;
  border-color:#ccc;
  color:#333;
  background-color:#fff;
}
.tg th{
  font-family:Arial, sans-serif;
  font-size:14px;
  font-weight:normal;
  padding:10px 5px;
  border-style:solid;
  border-width:0px;
  overflow:hidden;
  word-break:normal;
  border-color:#ccc;
  color:#333;
  background-color:#f0f0f0;
}

.line{
    float: left;
    width: 100px;
    height: 16px;
    margin-top: 12px;
    border-radius: 7px;
    
    @include background-gradient;
    animation: shine-lines $animation-duration infinite ease-out;
}

 
@keyframes shine-lines{
    0% { background-position: -100px;}
    40%, 100% {background-position: 140px;}
}
